import React, { FC, Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { validateEmail } from '../../utility/form_utilities';
import { classNames } from '../../utility/utility';
import { useShareListSort } from '../../hooks/api/ListSort/useShareListSort';
import { LoadingSpinner } from '../icons/OurIcons';

interface ShareListSortModalProps {
  isOpen: boolean;
  onClose: () => void;
  listSortId: string;
  listSortName: string;
}

const ShareListSortModal: FC<ShareListSortModalProps> = ({
  isOpen,
  onClose,
  listSortId,
  listSortName
}) => {
  const [ invalidEmail, setInvalidEmail ] = useState(false);
  const [ formData, setFormData ] = useState({ id: listSortId, email: null, share_decision_data: false });
  const { shareListSort, isPending } = useShareListSort();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === 'checkbox') {
      return setFormData({ ...formData, [e.target.name]: e.target.checked });
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    if (validateEmail(formData.email)) {
      setInvalidEmail(false);
    } else {
      setInvalidEmail(true);
      return;
    }

    await shareListSort(formData);
    onClose();
  };

  const ShareListSortForm: React.FC = () => {
    return (
      <>
        <div className="bg-white p-4 rounded-md" id="share-list-sort-form">
          <form>
            <div className="mb-4">
              {invalidEmail && <span className='text-red-500'>Please provide a valid email</span>}
              <label htmlFor="name" className={classNames('block text-gray-600 text-start', (invalidEmail && 'text-red-500'))}>Email</label>
              <input
                type="email"
                id="email"
                name="email"
                defaultValue={formData.email}
                placeholder="Enter the email you'd like to share with"
                onChange={handleInputChange}
                className={classNames('w-full border rounded-md px-3 py-2', (invalidEmail && 'border-red-500'))}
                required
              />
            </div>
            <div className="mb-4">
              <label className="flex items-center space-x-3">
                <input
                  type="checkbox"
                  name="share_decision_data"
                  defaultChecked={formData.share_decision_data}
                  onChange={handleInputChange}
                  className="form-checkbox h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                />
                <span className="text-gray-700">Share current decision data?</span>
              </label>
            </div>
          </form>
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
              onClick={handleSubmit}
              disabled={false}
            >
                  Share
            </button>
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
              onClick={onClose}
            >
                  Cancel
            </button>
          </div>
        </div>

      </>
    );
  };

  const ShareListSortDialog: React.FC<{children: React.ReactNode}> = ({ children }) => {
    return (
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h2" className="text-xl font-semibold">
                          Share List Sort
                      </Dialog.Title>
                      <div className="text-sm text-gray-400 mb-2">
                          List to share: {listSortName}
                      </div>
                      {children}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  if (isPending) {
    return (
      <ShareListSortDialog>
        <LoadingSpinner />
      </ShareListSortDialog>
    );
  }

  return (
    <ShareListSortDialog>
      <ShareListSortForm />
    </ShareListSortDialog>
  );
};

export default ShareListSortModal;
