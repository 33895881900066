
import React from 'react';

import FullAddressSearch from '../propertySearch/Components/FullAddressSearch';
import FilterSummaryAndActions from '../common/FilterSummaryAndActions';

const Header = ({ }) => {
  return (
    <div id="property-list-viewer-header" className="flex justify-between items-center h-18">
      <FullAddressSearch />
      <FilterSummaryAndActions />
    </div>
  );
};

export default Header;
