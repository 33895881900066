import { useMutation } from '@tanstack/react-query';
import { shareListSort } from '../../../api/ListSort/shareListSort';

export function useShareListSort() {
  const updateListSortMutationFunction = async ({ id, email, share_decision_data }) => {
    const requestBody = {
      email: email,
      share_decision_data: share_decision_data
    };
    return await shareListSort({ id: id, requestBody: requestBody });
  };

  const shareListSortMutation = useMutation({
    mutationFn: (body: { id: string, email: string, share_decision_data: boolean }) => updateListSortMutationFunction(body)
  });

  return {
    shareListSort: shareListSortMutation.mutateAsync,
    isSuccess: shareListSortMutation.isSuccess,
    isError: shareListSortMutation.isError,
    isPending: shareListSortMutation.isPending,
    error: shareListSortMutation.error,
    response: shareListSortMutation.data,
  };
}

