import { MapBoundingBox } from './types';
import { Anchor, PointLike } from 'mapbox-gl';
import { MapRef } from 'react-map-gl';
import { AddressType, FormattedPropertyType, PropertyDataProps } from './types';

export function capitalizeWordsWithUnderscore(str) {
  const words = str.split('_');
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  return capitalizedWords.join(' ');
}

export const capitalizeString = (capitalizeString: string) => {
  return capitalizeString.charAt(0).toUpperCase() + capitalizeString.slice(1);
};

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const getMapboxBoundingBox = (mapRef: MapRef) => {
  if (!mapRef) return null;

  const container = mapRef.getContainer().getBoundingClientRect();
  return {
    left: container.left,
    right: container.right,
    top: container.top,
    bottom: container.bottom
  };
};

export const getMapboxPopupOrientationAndOffset = (
  markerRef: mapboxgl.Marker,
  mapBoundingBox: MapBoundingBox,
  popupWidth: number,
  customOffset?: MapBoundingBox
): [Anchor, PointLike] => {

  if (!markerRef) return null;

  let orientation;
  let heightOffset;
  const markerCoordinates = markerRef.getElement().getBoundingClientRect();

  if (isMarkerInTopHalfOfMap(markerCoordinates, mapBoundingBox)) {
    orientation = 'top';
    heightOffset = customOffset?.top ?? 15;
  }
  else {
    orientation = 'bottom';
    heightOffset = customOffset?.bottom ?? -15;

  }

  const offset: PointLike = [ getWidthOffset(markerCoordinates, mapBoundingBox, popupWidth, customOffset), heightOffset ];

  return [ orientation, offset ];
};

const isMarkerInTopHalfOfMap = (marker: DOMRect, boundingBox: MapBoundingBox): Boolean => {
  return (boundingBox.bottom + boundingBox.top) / 2 > (marker.top + marker.bottom) / 2;
};

const getWidthOffset = (
  marker: DOMRect,
  boundingBox: MapBoundingBox,
  popupWidth: number,
  customOffset?: MapBoundingBox
): number => {
  const halfOfWidthPlusPadding = popupWidth / 2 + 5;
  const leftOffset = halfOfWidthPlusPadding + boundingBox.left - marker.left;
  const rightOffset = boundingBox.right - halfOfWidthPlusPadding - marker.right;

  if (leftOffset > 0) {
    return customOffset?.left ?? leftOffset;
  }
  else if (rightOffset < 0) {
    return customOffset?.right ?? rightOffset;
  }
  return 0;
};

export const formatPropertiesForTable = (properties: PropertyDataProps[]): FormattedPropertyType[] => {
  return Object.values(properties).map((property, index) => {
    if (!property) {
      return null;
    }
    let newObj = {
      index: index,
      id: property.id,
      property_id: property.id,
      favoriteStatus: property.property_favorite_flags?.length > 0,
      fullAddress: formatAddressFromPieces(property.address),
      avm_result: property.most_recent_avm_result?.estimated_value,
      potential_equity: property.most_recent_avm_result?.potential_equity,
      avm_price_per_sqft: property.most_recent_avm_result?.estimated_dollar_per_sqft,
      city: property.address?.city,
      zipcode: property.address?.zipcode,
      state_abbreviation: property.address?.state,
      ...property
    };
    delete newObj.most_recent_avm_result;
    delete newObj.address; // This line removes the 'address' key from newObj (we are getting it specifically above)
    return newObj;
  });
};

export const formatAddressFromPieces = (address: AddressType) => {
  if (!address) {
    return 'No Valid Address Provided';
  }
  const fullAddress = address?.full_address || address?.search_string || `${address.street } ${address.state} ${address.zipcode}` || 'No Valid Address Provided';
  // Remove the ', USA' from the address
  let modifiedAddress = fullAddress.replace(', USA', '');
  return modifiedAddress;
};
