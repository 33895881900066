import React, { type FC } from 'react';
import { formatDate, formatFloat, getPropertyAddress } from '../../utility/utility_functions';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { abandonFocus, maintainFocus, setFocusedIndex } from '../../redux/state/avmMapSlice';

type ActiveAvmCompsTableProps = {
  comps: any;
};

const tdStyling = 'p-3 text-xs';
const compsHeaders = [
  'Rank',
  'Date Listed',
  'MLS #',
  'Address',
  'List Price',
  '$ / Sq Ft',
  'Sq Ft',
  'Beds',
  'Baths',
  'Stories',
  'Year Build',
  'CDOM',
  'Sale Type',
  'Pool',
  'Score Comp'
];

const ActiveAvmCompsTable: FC<ActiveAvmCompsTableProps> = ({ comps }) => {
  const isActive = true;
  const dispatch = useDispatch<AppDispatch>();
  const focusedIndex = useSelector((state: RootState) => state.avmMap.focusedIndex);
  const keepFocus = useSelector((state: RootState) => state.avmMap.maintainFocus);

  return (
    <table className="table-auto divide-y divide-gray-300 rounded-xl w-full">
      <thead className='rounded-xl'>
        <tr className='sticky bg-green-500 text-white text-center whitespace-nowrap rounded-xl'>
          {compsHeaders?.map((h) => <th key={h} className='p-2 text-sm text-bold'>{h}</th>)}
        </tr>
      </thead>
      {comps?.map((comp, index) => {

        const linkToProperty = (text: string) => {
          const propertyRoute = `/properties/${comp?.property?.id}`;
          return (
            <a href={propertyRoute} target='_blank' className='text-green-600 hover:text-green-900'>
              {text}
            </a>
          );
        };

        const getSaleType = (): string => {
          const howSold = comp?.property?.how_sold;
          if (!howSold) return 'N/A';
          return howSold === '_1SLLR' ? 'Owner Financed' : howSold;
        };

        const clearEmphasis = () => {
          if (!keepFocus) {
            dispatch(setFocusedIndex(null));
          }
        };

        const setEmphasisHover = () => {
          if (!keepFocus) {
            dispatch(setFocusedIndex({ index, isActive }));
          }
        };

        const setEmphasisClick = () => {
          if (!keepFocus) {
            dispatch(maintainFocus());
          }
          else if (index === focusedIndex?.index && focusedIndex?.isActive === isActive) {
            dispatch(abandonFocus());
          }
          else {
            dispatch(setFocusedIndex({ index, isActive }));
          }
        };

        const getListPricePerSqft = (comp) => {
          const listPrice = comp?.property?.list_price;
          const sqft = comp?.property?.sqft;
          if (listPrice == null || sqft == null || sqft == 0) {
            return null;
          }

          return `$${formatFloat(listPrice / sqft, 2)}`;
        };

        return (
          <tbody key={comp.id}>
            <tr className={`${index === focusedIndex?.index && focusedIndex.isActive === isActive ? 'bg-green-200' : ''} text-xs text-center whitespace-nowrap border`}
              onMouseEnter={setEmphasisHover}
              onMouseLeave={clearEmphasis}
              onClick={setEmphasisClick}>
              <td className={tdStyling}>{index + 1}</td>
              <td className={tdStyling}>{formatDate(comp?.property?.on_market_date) ?? 'N/A'}</td>
              <td className={tdStyling}>{comp?.property?.last_mls_id ?? 'N/A'}</td>
              <td className={tdStyling}>{linkToProperty(getPropertyAddress(comp?.property))}</td>
              <td className={tdStyling}>{comp?.property?.list_price ? `${'$'}` + formatFloat(comp?.property?.list_price, 0) : 'N/A'}</td>
              <td className={tdStyling}>{getListPricePerSqft(comp) ?? 'N/A'}</td>
              <td className={tdStyling}>{formatFloat(comp?.property?.sqft, 0) ?? 'N/A'}</td>
              <td className={tdStyling}>{comp?.property?.bedrooms ?? 'N/A'}</td>
              <td className={tdStyling}>{comp?.property?.bathrooms ?? 'N/A'}</td>
              <td className={tdStyling}>{comp?.property?.stories ?? 'N/A'}</td>
              <td className={tdStyling}>{comp?.property?.year_built ?? 'N/A'}</td>
              <td className={tdStyling}>{comp?.property?.cumulative_days_on_market ?? 'N/A'}</td>
              <td className={tdStyling}>{getSaleType() ?? 'N/A'}</td>
              <td className={tdStyling}>{comp?.property?.pool.includes('INGRN') || comp?.property?.pool.includes('POOL') ? 'Yes' : 'No'}</td>
              <td className={tdStyling}>{comp?.score_comp_value ?? 'N/A'}</td>
            </tr>
          </tbody>
        );
      })}
    </table>
  );
};

export default ActiveAvmCompsTable;
