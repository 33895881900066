import React, { type FC } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../../../assets/stylesheets/data-table-styles.css';
import { ListUploadTableProps } from '../../utility/types';
import { formatDate } from '../../utility/utility_functions';

const ListUploadTable: FC<ListUploadTableProps> = ({ listUploadsJson }) => {
  const listUploads = JSON.parse(listUploadsJson);
  const basePath = '/list-uploads';
  const returnPath = '/list-sorts';
  return (
    <>
      <div className='mt-4'>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className='text-2xl font-bold'>List Uploads</h1>
            <p>Upload lists of properties that you would like to run through the sorting process</p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 flex sm:flex-none">
            <a
              href={returnPath}
              type="button"
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
                Back to List Sorts
            </a>
            <a
              type="button"
              className="ml-3 block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              href={`${basePath}/new`}
            >
            Add List Upload
            </a>
          </div>
        </div>

        <DataTable
          className='list-uploads-data-table'
          value={listUploads}
          sortField="last_sync_date"
          sortOrder={-1}
          emptyMessage="No uploads found"
          style={{ marginTop: '1rem' }}
          pt={{
            table: { className: 'list-uploads-data-table' },
            headerRow: { className: 'list-uploads-header-row' },
          }}
        >
          <Column
            field="list_name"
            header="List Name"
            className='datatable-list-uploads-list-name-column'
            sortable
          />
          <Column
            field="number_of_rows"
            header="Number of Rows"
            sortable
          />
          <Column
            field="parsed"
            header="Finished Parsing"
            sortable
          />
          <Column
            field="status"
            header="Status"
            sortable
          />
          <Column
            field="last_sync_date"
            header="Last Sync'd"
            sortable
            body={(rowData) => formatDate(rowData.last_sync_date) ?? 'null'}
          />
          <Column
            className='datatable-list-uploads-show-column'
            field="show"
            header="Show"
            bodyStyle={{ textAlign: 'center', fontSize: '14px', color: 'blue ' }}
            body={(rowData) => <a href={`/list-uploads/${rowData.id}`}>Show</a>}
          />
        </DataTable>
      </div>
    </>
  );
};

export default ListUploadTable;
