
import React, { useState, useEffect, FC } from 'react';
import { VirtualScroller, VirtualScrollerTemplateOptions } from 'primereact/virtualscroller';
import { classNames } from 'primereact/utils';
import { LoadingSpinner } from '../icons/OurIcons';
import { useFilteringContext } from '../../contexts/Filtering';
import useGetProperties from '../../hooks/api/Property/useGetProperties';
import { PropertyDataProps } from '../../utility/types';
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';
import FullAddressSearch from '../propertySearch/Components/FullAddressSearch';
import useDebouncedIncrementor from '../../hooks/useDebouncedIncrement';
import DMPropertyIncrementor from './DMPropertyIncrementor';
import { ArrowsUpDownIcon } from '@heroicons/react/24/outline';
import SortModal from './SortModal';
import useSessionStorage from '../../hooks/useSessionStorage';

export interface DecisionMakingListProperty {
  id: string;
  wanted: boolean;
  address: string;
}

export interface LitePropertyListProps {
  listSortId: string;
  activePropertyId: string;
  setActivePropertyId: (propertyId: string) => void;
  isSidebarCollapsed?: boolean;
}
function formatPropertiesForDM(propertiesData: PropertyDataProps[]): DecisionMakingListProperty[] {
  return propertiesData?.map(property => ({
    id: property.id,
    address: property.address?.full_address,
    wanted: true
  }));
}

const LitePropertyList: FC<LitePropertyListProps> = ({ listSortId, activePropertyId, setActivePropertyId, isSidebarCollapsed = false }) => {
  const paginationPerPageCount = 25;
  const [ propertiesCache, setPropertiesCache ] = useState<DecisionMakingListProperty[]>();
  const { ransackObj, filteringDispatch, isFilteringActive, sorting } = useFilteringContext();
  const [ first, setFirst ] = useState<number>(0);
  const [ totalPropertyRecordsFilteredLocal, setTotalPropertyRecordsFilteredLocal ] = useState<number>(0);
  const [ currentActivePropertyPage, setCurrentActivePropertyPage ] = useState<number>(0);
  const [ isSortModalOpen, setIsSortModalOpen ] = useState<boolean>(false);
  const { storedValue: page, setValue: setPage } = useSessionStorage('decisionMakingPageNumber', 0);

  const {
    propertiesData,
    totalPropertyRecordsFiltered,
    loadingProperties
  } = useGetProperties(listSortId, ransackObj, paginationPerPageCount, page+1, null);

  useEffect(() => {
    if (propertiesData) {
      setPropertiesCache(formatPropertiesForDM(propertiesData));
    }
  }, [ propertiesData, totalPropertyRecordsFiltered ]);

  useEffect(() => {
    setPage(0);
    setFirst(0);
    setCurrentActivePropertyPage(0);
    setActivePropertyId(null);
  }, [ listSortId ]);

  useEffect(() => {
    if (propertiesCache && !activePropertyId) {
      setActivePropertyId(propertiesData[first % paginationPerPageCount || 0]?.id);
    }
  }, [ propertiesCache ]);

  useEffect(() => {
    if (totalPropertyRecordsFiltered && totalPropertyRecordsFiltered!== totalPropertyRecordsFilteredLocal) {
      setTotalPropertyRecordsFilteredLocal(totalPropertyRecordsFiltered);
    }
  }, [ totalPropertyRecordsFiltered ]);

  const onPageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    setPage(Math.floor(event.first / paginationPerPageCount));
  };

  const handleIncrement = () => {
    const index = first % paginationPerPageCount;
    let incrementedFirst = first + 1;
    let incrementedPage = page + 1;
    // Check if we are still within the current page
    if (index < paginationPerPageCount - 1) {
      // Increment within the page
      setFirst(incrementedFirst);
      setActivePropertyId(propertiesCache[incrementedFirst % paginationPerPageCount].id);
    } else {
      // If we are at the end of the current page, check if there's another page
      if (incrementedPage < totalPropertyRecordsFiltered) {
        // Move to the next page
        setPage(incrementedPage);
        setFirst(incrementedFirst);
        setCurrentActivePropertyPage(incrementedPage);
        setActivePropertyId(null); // we dont yet have the new propertyCache
      }
    }
  };

  const handleDecrement = () => {
    const index = first % paginationPerPageCount;
    let decrementedFirst = first - 1;
    let decrementedPage = page - 1;
    // Check if we are still within the current page
    if (index > 0) {
      // Decrement within the page
      setFirst(decrementedFirst);
      setActivePropertyId(propertiesCache[decrementedFirst % paginationPerPageCount].id);
    } else {
      // If we are at the first property of the current page, check if there's a previous page
      if (page > 0) {
        // Move to the previous page
        setPage(decrementedPage);
        setCurrentActivePropertyPage(decrementedPage);
        setFirst(decrementedFirst);
        setActivePropertyId(null); // we dont yet have the new propertyCache
      }
    }
  };

  const onIncrementorClick = (incrementValue: number) => {
    debouncedIncrementor(incrementValue); // Use debouncer
  };

  const debouncedIncrementor = useDebouncedIncrementor((accumulatedClicks: number) => {
    if (accumulatedClicks < 0) {
      handleDecrement();
    } else if (accumulatedClicks > 0) {
      handleIncrement();
    }
  }, 300);

  const handlePropertyClick = (property: DecisionMakingListProperty, itemIndex: number) => {
    setActivePropertyId(property.id);
    setFirst(page * paginationPerPageCount + itemIndex);
    setCurrentActivePropertyPage(page);
  };

  const itemTemplate = (item: DecisionMakingListProperty, options: VirtualScrollerTemplateOptions) => {
    const className = classNames('flex align-items-center p-2',
      {
        'bg-slate-50 hover:bg-slate-100 text-black': options.odd,
        'bg-white hover:bg-slate-100 text-black': options.even,
        '!bg-indigo-500 !text-white': item.id === activePropertyId
      });

    return (
      <div
        key={item.id}
        className={className}
        style={{ height: options.props.itemSize + 'px' }}
        onClick={() => handlePropertyClick(item, options.index)}
      >
        {item.address}
      </div>
    );
  };

  const PropertiesScroller = () => {
    if (loadingProperties) {
      return (
        <div className='mt-24 flex items-center justify-center h-96 ' >
          <LoadingSpinner />
        </div>
      );
    }

    if (!propertiesCache?.length) {
      return (
        <div>No properties </div>
      );
    }

    return (
      <VirtualScroller
        key={`virtual-scroller-${listSortId}`}
        items={propertiesCache}
        itemSize={50}
        itemTemplate={itemTemplate}
        loadingIcon={<LoadingSpinner />}
        showLoader
        loading={loadingProperties}
        className="border-1 surface-border border-round"
        style={{ width: '200px', height: '800px' }}
      />
    );
  };

  const isPageStale = page !== currentActivePropertyPage;

  const isPrevDisabled = () => {
    if (isSidebarCollapsed) {
      return page === 0 && first === 0;
    }
    else {
      return isPageStale || (page === 0 && first === 0);
    }
  };

  const isNextDisabled = () => {
    if (isSidebarCollapsed) {
      return page * paginationPerPageCount + first >= totalPropertyRecordsFilteredLocal - 1;
    }
    else {
      return isPageStale || page * paginationPerPageCount + first >= totalPropertyRecordsFilteredLocal - 1;
    }
  };

  const CustomSidebarIncrementor = () => {
    return (
      <div id='CustomSidebarIncrementor-container' className={'flex justify-between p-3'}>
        <button
          className={`p-button p-component p-button-text
            ${isPrevDisabled() ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-100 active:bg-gray-200' }`}
          onClick={() => onIncrementorClick(-1)}
          disabled={isPrevDisabled()}
        >
          Prev
        </button>
        <button
          className={`p-button p-component p-button-text
            ${isNextDisabled() ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-100 active:bg-gray-200' }`}
          onClick={() => onIncrementorClick(1)}
          disabled={isNextDisabled()}
        >
          Next
        </button>
      </div>
    );
  };

  const ActiveFilters = () => {
    if (isFilteringActive) {
      return (
        <div id='LitePropertyListFilter-container'>
          <div className='flex justify-between m-1'>
            <div className=''> Filtered: {totalPropertyRecordsFilteredLocal} properties</div>
            <button
              className="rounded whitespace-nowrap bg-indigo-600 px-2 text-xs py-0 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => filteringDispatch({ type: 'RESET_FILTERS' })}
            >Clear</button>
          </div>
        </div>
      );
    };
    return null;
  };

  return (
    <>
      {isSortModalOpen &&
        <SortModal
          isOpen={isSortModalOpen}
          setIsOpen={setIsSortModalOpen}
        />
      }
      {isSidebarCollapsed ? (
        <DMPropertyIncrementor
          index={first} // The current index within the page
          totalPropertyRecordsFiltered={totalPropertyRecordsFilteredLocal}
          loadingProperties={loadingProperties}
          onIncrementorClick={onIncrementorClick} // Pass the parent's incrementor function
        />
      ) : (
        <div id="property-list-lite" className="flex flex-col card flex flex-col justify-content-center rounded-xl shadow-2xl border-2 border-slate-200">
          <div className='h-full'>
            <div className='flex items-center flex-col align-middle lg:justify-end w-full justify-center md:mt-3 mr-5'>
              <div className='flex flex-col w-[220px]'>
                <div className='absolute '>
                  <ArrowsUpDownIcon
                    id='sort-icon'
                    className={`w-4 cursor-pointer hover:bg-gray-100 hover:rounded-full ${sorting?.field ? 'text-blue-500 bg-blue-100 rounded-full p-1' : ''}`}
                    onClick={() => setIsSortModalOpen(true)}
                  />
                </div>
                <Paginator
                  className='mb-2'
                  first={first}
                  rows={paginationPerPageCount}
                  totalRecords={totalPropertyRecordsFilteredLocal}
                  onPageChange={onPageChange}
                  template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
                />
                <FullAddressSearch />
                <ActiveFilters />
                <CustomSidebarIncrementor />
                <PropertiesScroller />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LitePropertyList;
