import React, { type FC } from 'react';
import { formatDate } from '../../../../utility/utility_functions';

export type AvmCalculationDatesProps = {
  soldAvmResult: any;
  activeAvmResult: any;
}

const smallTxtStyling = 'text-xs pt-1 whitespace-nowrap';

const AvmCalculationDates: FC<AvmCalculationDatesProps> = ({ soldAvmResult, activeAvmResult }) => {

  return (
    <div className='place-content-start m-5 text-left'>
      <hr className='pt-3'/>
      <div className='flex'>
        <div className='pr-5'>
          <h6 className='text-sm mb-2 font-semibold'>Sold Comps Date Calculated</h6>
          <div className={smallTxtStyling}>{formatDate(soldAvmResult?.result?.time_of_calculation)}</div>
          <div className='text-xs pb-2 whitespace-nowrap text-gray-400'>{activeAvmResult?.result?.avm_version}</div>
        </div>
        <div>
          <hr className='w-px h-20 bg-black border-0' />
        </div>
        <div className='pl-5'>
          <h6 className='text-sm mb-2 font-semibold'>Active Comps Date Calculated</h6>
          <div className={smallTxtStyling}>{formatDate(activeAvmResult?.result?.time_of_calculation)}</div>
          <div className='text-xs pb-2 whitespace-nowrap text-gray-400'>{activeAvmResult?.result?.avm_version}</div>
        </div>
      </div>
    </div>
  );
};
export default AvmCalculationDates;
